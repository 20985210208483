import React from 'react';
import { WrapperInput } from './styles';
import { TextField } from '../TextField';
import { FormHandles } from '@unform/core';
import { useMediaQuery } from '@mui/material';
import { canvasSize } from '@/utils/canvasMatches';

interface IProps {
  widthOffice: string;
  widthInstitution: string;
  ref: React.RefObject<FormHandles>;
}

export const InstitutionsFields: React.FC<IProps> = ({ ref, widthInstitution, widthOffice }) => {
  const matches = useMediaQuery(canvasSize);

  return (
    <>
      {!matches && (
        <WrapperInput>
          <TextField
            width={widthInstitution}
            name='instituicao'
            placeholder='Instituição'
            onChange={() => ref?.current?.setFieldError('instituicao', '')}
          />
          <TextField
            width={widthOffice}
            name='cargo'
            placeholder='Cargo'
            onChange={() => ref?.current?.setFieldError('cargo', '')}
          />
        </WrapperInput>
        )}
        {matches && (
        <>
          <WrapperInput>
            <TextField
              name='instituicao'
              placeholder='Instituição'
              onChange={() => ref?.current?.setFieldError('instituicao', '')}
            />
          </WrapperInput>
          <WrapperInput>
            <TextField
              name='cargo'
              placeholder='Cargo'
              onChange={() => ref?.current?.setFieldError('cargo', '')}
            />
          </WrapperInput>
        </>
      )}
    </>
  );
};
