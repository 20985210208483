import React from 'react';
import { Container } from './styles';
import { useField } from '@unform/core';

interface IProps  {
  name: string;
  width?: string;
  required?: boolean;
  placeholder?: string;
  onChange?: () => void;
}

export const TextArea: React.FC<IProps> = ({ name, placeholder, width, onChange, required=false }) => {
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const { fieldName, defaultValue, registerField } = useField(name);
  const [internalPlaceHolder, setInternalPlaceHolder] = React.useState(placeholder);

  React.useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: ref => ref.value
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <textarea
        ref={inputRef}
        style={{ width }}
        required={required}
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={internalPlaceHolder}
        onFocus={() => setInternalPlaceHolder('')}
        onBlur={() => {
          if (!inputRef.current?.value) {
            setInternalPlaceHolder(placeholder);
          }
        }}
      />
    </Container>
  );
};
