import {
  Title,
  Close,
  Header,
  Overlay,
  WrapperInput,
  ErrorWrapper,
  FormContainer,
  ContentWrapper,
  ExclamationMark
} from './styles';
import React from 'react';
import { Form } from '@unform/web';
import { ContactService } from '@/service';
import { useMediaQuery } from '@mui/material';
import { activity } from '@/utils/mainActivity';
import { canvasSize } from '@/utils/canvasMatches';
import { emailValidator } from '@/utils/emailValidator';
import { IReqContact } from '@/service/endpoints/contact';
import { FormHandles, SubmitHandler } from '@unform/core';
import { TextArea } from '@/global/components/Inputs/TextArea';
import { TextField } from '@/global/components/Inputs/TextField';
import { SelectField } from '@/global/components/Inputs/SelectField';
import { Activities } from '@/global/components/Inputs/ActivitesOptions';
import { MunicipiesField } from '@/global/components/Inputs/SelectMunicipes';
import { InstitutionsFields } from '@/global/components/Inputs/InstitutionFields';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const ContantForm: React.FC<IProps> = ({ open, onClose }) => {
  const isMobile = useMediaQuery(canvasSize);
  const [error, setError] = React.useState('');
  const { sendMessage } = new ContactService();
  const formRef = React.useRef<FormHandles>(null);

  const handleSubmit: SubmitHandler<any> = data => {
    const copy = {...data};

    const { status, message } = emailValidator(copy.email);

    if (!status) {
      setError(message);
      formRef.current?.setFieldError('email', message);
      return;
    }

    const dontHaveSelecValue = Object.entries(copy).some(item => item[1] === '0');
    if (dontHaveSelecValue) {
      Object.entries(copy).forEach(([key, value]) => {
        if (value === '0') {
          formRef.current?.setFieldError(key, 'error');
        }
      });
      setError('Campo(s) obrigatório(s)');
      return;
    }
    const json = {
      ...copy,
      emailTo: 'gustavo.cervus@gmail.com',
      token: '$2y$10$GcHxQo9ozoCGiqTvw5N/FOYtH/cUe8WZdHT9ITB2E1lzLWs0lrxtW',
    } as IReqContact;

    sendMessage(json).then(({ data }) => {
      onClose()
    });
  };

  return (
    <>
      <ContentWrapper open={open}>
        <Header>
          <Title>CONTATO</Title>
          <Close onClick={onClose}/>
        </Header>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <WrapperInput>
              <TextField
                required
                name='nome'
                placeholder='Nome completo*'
                onChange={() => formRef.current?.setFieldError('nome', '')}
              />
            </WrapperInput>
            <MunicipiesField
              widthUf='240px'
              clear={() => formRef.current?.setFieldError('atividade', '')}
              widthMunicipie='225px'
            />
            <WrapperInput>
              <SelectField
                defaultValue='0'
                options={activity}
                name="atividade"
                defaultItem='Atividade Principal*'
                width={isMobile ? '100%' : '480px'}
                onChange={() => formRef.current?.setFieldError('atividade', '')}
              >
              <Activities />
              </SelectField>
            </WrapperInput>
            <InstitutionsFields
              ref={formRef}
              widthOffice='225px'
              widthInstitution='240px'
            />
            <WrapperInput>
              <TextField
                required
                name='email'
                width={isMobile ? '100%' : '480px'}
                placeholder='Email*'
                onChange={() => formRef?.current?.setFieldError('email', '')}
              />
            </WrapperInput>
            <WrapperInput>
              <TextArea
                required
                width={isMobile ? '93%' : '480px'}
                name='mensagem'
                placeholder='Mensagem*'
                onChange={() => formRef?.current?.setFieldError('mensagem', '')}
              />
            </WrapperInput>
            <button>Enviar</button>
            {!!error === true && (
            <ErrorWrapper>
              <ExclamationMark />
              <b>{error}</b>
            </ErrorWrapper>
            )}
          </Form>
        </FormContainer>
      </ContentWrapper>
      <Overlay open={open} onClick={onClose} />
    </>
  );
};
