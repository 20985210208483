import React from 'react';
import { cities } from '@/utils/municipies';

export function useMunicipies() {
  const [municipies, setMunicipies] = React.useState<string[]>([]);
  const [federalUnion, setFederalUnion] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (federalUnion) {
      const data = cities?.map(item =>
        item.sigla === federalUnion
        ? item.cidades
        : null
      ).filter(item => item !== null)[0];
      setMunicipies(data as string[]);
    }
  }, [federalUnion]);

  return  {
    municipies,
    federalUnion,
    setFederalUnion,
  };
}
