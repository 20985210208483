import React from 'react';
import { WrapperInput } from './styles';
import { useMunicipies } from './service';
import { estados } from '@/utils/estados';
import { FormHandles } from '@unform/core';
import { SelectField } from '../SelectField';
import { useMediaQuery } from '@mui/material';
import { canvasSize } from '@/utils/canvasMatches';

interface IProps {
  widthUf: string;
  widthMunicipie: string;
  clear: () => void;
}

export const MunicipiesField: React.FC<IProps> = ({ clear, widthMunicipie, widthUf }) => {
  const matches = useMediaQuery(canvasSize);
  const { municipies, setFederalUnion } = useMunicipies();
  return (
    <>
      {!matches && (
        <WrapperInput>
          <SelectField
            name='uf'
            width={widthUf}
            required={true}
            defaultValue='0'
            defaultItem='UF*'
            options={estados}
            onChange={e => {
              setFederalUnion(e.target.value);
              clear();
            }}
          >
          {estados.map(option => (
            <option key={option.id} value={option.sigla}>
              {option.sigla}
            </option>
          ))}
          </SelectField>
          <SelectField
            width={widthMunicipie}
            name='municipio'
            defaultValue='0'
            defaultItem='Município*'
            options={municipies as unknown as Record<string, unknown>[]}
            onChange={() => clear()}
          >
          {municipies?.map((item: any, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
          </SelectField>
        </WrapperInput>
      )}
      {matches && (
        <>
          <WrapperInput>
            <SelectField
              name='uf'
              width='100%'
              defaultItem='UF'
              defaultValue='0'
              options={estados}
              onChange={e => {
                setFederalUnion(e.target.value);
                clear()
              }}
            >
            {estados.map(option => (
              <option key={option.id} value={option.sigla}>
                {option.sigla}
              </option>
            ))}
            </SelectField>
          </WrapperInput>
          <WrapperInput>
            <SelectField
              name='municipio'
              defaultValue='0'
              defaultItem='Município'
              options={municipies as unknown as Record<string, unknown>[]}
              onChange={() => clear()}

            >
            {municipies?.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
            </SelectField>
          </WrapperInput>
        </>
      )}
    </>
  );
};
