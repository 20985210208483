import {
  Label,
  LinkIn,
  Container,
  LayerIcon,
  FooterInfo,
  ButtonGrenn,
  SecondLayer,
} from './style';
import Link from 'next/link';
import { useAuth } from '@/hooks';
import { ContantForm } from './Contact';
import { estados } from '@/utils/estados';
import { useFooterService } from './service';
import SintesePath from 'public/sintese_bg.png';
import React, { useEffect, useMemo, useState } from 'react';
import { SelectOur } from '@/components/viewIndicator/molecules/FilterSection/Filter';

interface IProps {
  area: string;
  isHome?: boolean;
  modifyItem?: boolean;
  isIndicator?: boolean;
  displacement?: boolean;
  isNavigation?: boolean;
}

export const Footer: React.FC<IProps> = ({
  area,
  modifyItem,
  isIndicator,
  displacement,
  isHome = false,
  isNavigation=false,
}) => {
  const isModify = modifyItem || false;
  const { setOpen, open } = useFooterService();
  const { setOpenRegister, openRegister } = useAuth();
  const [currentYear, setCurrentYear] = useState<number>(2022);

  useEffect(() => {
    const date = new Date();
    setCurrentYear(date.getFullYear());
  }, []);

  const StatesSelect = useMemo(() => {
    const newArray: any = [];

    estados.forEach((element: any) => {
      newArray.push({
        code: element.sigla,
        title: element.sigla,
        pdf: element?.pdf
      })
    });

    return newArray;
  }, [estados]);

  return <Container modifyItem={isModify} area={area} displacement={displacement}>

    <SecondLayer>
      <div className='test white'>
        <div className='boxContainer' >
          <h4>Tenha acesso exclusivo a informações e dados dos Desafios da Gestão Estadual</h4>
          <ButtonGrenn style={{ marginTop: '20px' }} onClick={() => setOpenRegister(!openRegister)}>Quero ter acesso</ButtonGrenn>
        </div>
      </div>
      <div className='test black'>

        <div className='boxContainer '>
          <div className='box box-left'>
            <h4>Gestor público, quer saber como enfrentar os desafios do seu estado?</h4>
            <div onClick={() => setOpen(!open)} className='btn'>
              <p>Quero saber</p>
            </div>
          </div>
          <div className='teste'></div>
          <div className='box box-rigth'>
            <p className='title'>Como podemos ajudar?</p>
            <div className='flex'>
              <div>
                <LayerIcon>
                  <div className='visto'> <img src="visto.png" alt="visto" /></div>
                  <p>Políticas com base em dados e evidências</p>
                </LayerIcon>
                <LayerIcon>
                  <div className='visto'> <img src="visto.png" alt="visto" /></div>
                  <p>Diagnóstico preciso</p>
                </LayerIcon>

              </div>
              <div>
                <LayerIcon>
                  <div className='visto'> <img src="visto.png" alt="visto" /></div>
                  <p>Políticas com base em dados e evidências</p>
                </LayerIcon>
                <LayerIcon>
                  <div className='visto'> <img src="visto.png" alt="visto" /></div>
                  <p>Diagnóstico preciso</p>
                </LayerIcon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SecondLayer>
    <FooterInfo>
      <div className='container'>
        <div className='boxChild'>
          <Link href="https://www.analytics.macroplan.com.br/">
            <img src="logo_macroplan.png" alt="" />
          </Link>
          <div className='info'>
            <div className="containerBox">
              <div className="boxText">
                <img src="/icons/desktop.png" alt="Minha Figura"></img>
                <Link href="https://www.analytics.macroplan.com.br/">
                  <Label className='hover'>www.analytics.macroplan.com.br</Label>
                </Link>
              </div>
              <div className="boxText">
                <img src="/icons/mail.png" alt="Minha Figura"></img>

                <Label>macroplan@macroplan.com.br</Label>
              </div>
              <div className="boxText">

                <div className='wrap'>
                  <div className='wraperCell'>
                    <img src="/icons/tel.png" alt="Minha Figura"></img>
                    <Label>(21) 2287-3293</Label>
                  </div>
                  <Link href="https://www.linkedin.com/company/93131/admin">
                    <LinkIn />
                  </Link>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className='boxCopy'>
          <Label>{currentYear} Macroplan© </Label>
          <b>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href='/privacy/Politica_de_Privacidade.pdf'
            >
              Política de privacidade
            </a>
          </b>
        </div>
      </div>
    </FooterInfo>
    <ContantForm open={open} onClose={() => setOpen(!open)} />
  </Container>;
};
