import map from './assets/map.svg';
import tell from './assets/tell.svg';
import email from './assets/email.svg';
import styled from 'styled-components';
import site from './assets/website.svg';
import link from './assets/linkedin.svg';
import Ilustração from './assets/ilus.svg';

interface IProps {
  area: string;
  modifyItem?: boolean;
  displacement?: boolean;
}

interface FirstLayerProps {
  isIndicator?: boolean;
}

export const IconBase = styled(Ilustração)`
width:300px;
margin-bottom: 10px;
`;


export const Container = styled.footer<IProps>`
  grid-area: ${props => props.area};
  select{
    width: 140px;
    height: 34px;
    color: black !important;
  }

  @media (max-width: 820px) {
    top: 0%;
  }

  .teste{
    height:2px;
    width:100%;
    background-color:#C4C4C4;
    margin-top:15px;
    display:block;

    @media (min-width: 820px) {
      display:none;

    }
  }
`;

export const FirstLayer = styled.div<FirstLayerProps>`
  width: 100%;
  height: 275px;
  display: flex;
  align-items: center;
  position: absolute;
  flex-direction: column;
  background: transparent;
  justify-content: center;

  .boxSelect{
      width: 250px;
      height: 280px;
      display: flex;
      background: #1A8271;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .p {
        opacity: 1;
        width: 250px;
        color: #FFFFFF;
        margin: 0 0 15px 0;
        text-align: center;
        letter-spacing: 0px;
        font: normal normal 600 29px/30px Poppins;
      }
    }

  .mt2{
    margin-top: 10px;
  }
  .boxContainer{
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center
  }
  h4{
    width: 426px;
    text-align: center;
    font: normal normal 800 22px/30px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 15px;
<<<<<<< HEAD
=======
    margin-top: 10px;
>>>>>>> 6a0946eddfff019ed92d444700ca9b88446fd428
    @media (max-width: 820px) {
    font: normal normal 800 20px/30px Poppins;

    }
  }
  button{
    width: 140px;
    background: #1A8271 0% 0% no-repeat padding-box;
    border-radius: 22px;
    opacity: 1;
    color:#FFFFFF;
    border: none;
    &:hover{
      background: #00000066 0% 0% no-repeat padding-box;
    }
  }
  @media (max-width: 820px) {
    clip-path: none;
    margin-top: 0;
    h4{
      text-align: left;
      width: auto;
    }
    .boxContainer{
      align-items: flex-start;
    }
  }
`;

export const SecondLayer = styled.div`
  width: 100%;
  height: auto;
  min-height: 568px; //568px
  background-image: url("graf.png");
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .boxContainer{
    width: 940px;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0 20px 0;

  }
  .box{
    width: 426px;
  }
  .box-left{
    margin-right: 42px;
    h4{
      text-align: left;
      font: normal normal 800 23px/30px Poppins;
      letter-spacing: 0px;
      color: #FFFFFF;
      height: 94px;
      margin: 0;
      margin-bottom: 18px;
      width: 335px;
    }
    p{
      margin: 0;
      text-align: center;
      font: normal normal 600 12px/26px Poppins;
      letter-spacing: 0.6px;
    }
    .btn{
      width: 140px;
      height: 30px;
      border: 1px solid #FFFFFF;
      border-radius: 22px;
      opacity: 1;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover{
      background:#FFFFFF80 0% 0% no-repeat padding-box;
      cursor: pointer;
    }
    }
  }
  .box-rigth{
    .title{
      text-align: left;
      font: normal normal bold 14px/18px Poppins;
      letter-spacing: 0.69px;
      color: #FFFFFF;
      opacity: 1;
      margin: 0;
      margin-bottom: 20px;
    }
  }
  .flex{
    display: flex;
  }

  .white{
    background-color: rgba(255,255,255,0.90) ;
    .boxContainer{
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center

    }
    h4{
      width: 426px;
      text-align: center;
      font: normal normal 800 23px/30px Poppins;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
    @media (max-width: 820px) {
    clip-path: none;
    margin-top: 0;
    h4{
      text-align: left;
      width: auto;
      font: normal normal 800 20px/30px Poppins;
    }
    .boxContainer{
      align-items: flex-start;
    }
  }
  }
  .black{
    background-color: rgb(14, 46, 56,0.90);
  }
  .test{
    width: 100%;
    display:flex ;
    justify-content:center ;
    height: 100%;
    min-height: 284px;
  }


  @media (max-width: 820px) {
   flex-direction: column;
    align-items: flex-start;
    background-size: 183% 100%;
    .boxContainer{
      flex-direction: column;
      align-items: flex-start;
      width: 90%;
    }
   .box-left{
    /* border-bottom: 1px solid #C4C4C4; */

      h4{
      font: normal normal 800 20px Poppins;
    }
    }
   .box-rigth{
    margin-top: 5%;
   }
   .flex{
    flex-direction: column;
   }
   .box{
    width: 90%;
   }

   .btn{
    margin-bottom: 30px;

   }
  }
`;

export const FooterInfo = styled.div`
  width: 100%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .wrap{
      display: flex ;
    }
  .wraperCell{
    display:flex ;
    justify-content:center ;
    align-items: center;
  }
  .hover{
    &:hover{
      text-decoration: underline;
    }
  }
  .containerBox{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;


    .boxText{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      img{
      width: 27px;
      height: 30px;
      margin-right: 5px;
    }
      svg{
        margin-right: 10px;
        margin-top: 0!important;
      }
    }

    @media (max-width: 820px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

  }
  .container {
    background-color: white;
    display: flex;
    width: 80%;
    flex-direction: column;
    margin-top: 30px;

    .boxChild{
      display: flex;
      border-bottom: 2px solid #C4C4C4;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 30px;
      img{
        cursor: pointer;

      }

    }

    .boxCopy{
      display: flex;
      align-items: center;
      margin: 20px  0;
      p{
        margin: 0 10px 0 0;

        @media (max-width: 820px) {
        margin: 5px 10px 0 0;

        }
      }
      b{
        letter-spacing: 1.69px;
        color: #000000;
        opacity: 1;
        text-decoration: underline;
        font-weight: bold;
        font-size: 13px;

      }

      &:hover{
        b{
          cursor: pointer;
          /* transform: scale(1.02); */
          color: var(--green);
        }
      }
    }
  }

  img{
    width: 120px;
    height: 60px;
  }

  .info{
    display: flex;

    .box{
      margin-right: 38px;

    }

    p{
      text-align: left;
      font: normal normal normal 14px/18px Poppins;
      letter-spacing: 0.42px;
      color: #000000;
      opacity: 1;
      /* margin: 0 0 10px 10px; */
    }
  }

  @media (max-width: 820px) {
    width: auto;
    .container{
      flex-direction: column;
      justify-content: space-between;
    width: 90%;

      .boxChild{
        flex-direction: column;
      }
      .boxCopy{
        flex-direction: column-reverse;
        align-items: flex-start;
      }
    }
    .info{
      flex-direction: column;
      margin-top: 4%;
    }

    .wrap{
      display: flex ;
      flex-direction:column ;
    }
  }
`;

export const LayerIcon = styled.div`
  display: flex;
  margin-bottom: 15px;
  .visto{
    width: 22px;
    height: 22px;
    min-width: 22px;
    background: #1A8271 ;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 9px;
  }

  p{
    font: normal normal normal 14px/18px Poppins;
    letter-spacing: -0.2px;
    color: #FFFFFF;
    margin: 0;
  }

  @media (max-width: 820px) {
    width: auto;
  }

`;

export const LayerFooter = styled.div`

  display: flex;
  display: flex;
    align-items: baseline;
  img{
    width: 15px;
    height: 11px;
    margin-right: 6.5px;
  }

`
export const Label = styled.p`
  font: normal normal normal 14px/18px Poppins !important;
    letter-spacing: 0.42px;
    color: #000000 !important;
    margin-right: 6px !important;
`
export const ButtonGrenn = styled.button`
  width: 140px;
  background: #1A8271 0% 0% no-repeat padding-box !important;
  border-radius: 22px;
  opacity: 1;
  color:#FFFFFF;
  border: none;
  &:hover{
    background: #1A827180 0% 0% no-repeat padding-box !important;
  }
`
export const Email = styled(email)``
export const Map = styled(map)``
export const Tell = styled(tell)``
export const Site = styled(site)`
/* width: 45px;
margin-right: -4px !important;
@media (max-width: 820px) {
margin-left: -14px;
width: 40px;
} */
`
export const LinkIn = styled(link)`
width: 50px;
`
