import React from 'react';

export const useFooterService = () => {
  const [open, setOpen] = React.useState(false);

  return {
    open,
    setOpen
  };
};
