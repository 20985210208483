import styled from 'styled-components';
import Exclamation from '../assets/exclamation.svg';

interface IProps {
  open: boolean;
}

export const ContentWrapper = styled.div<IProps>`
  top: 12px;
  opacity: 1;
  right: 45%;
  width: 560px;
  position: fixed;
  z-index: 9999999;
  max-height: 83vh;
  left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  height: fit-content;
  /* background: #FFFFFF 0% 0% no-repeat padding-box; */
  display: ${props => props.open ? 'block' : 'none'};

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
}
  @media (min-width: 800px) {
    right: 26%;
  }

  @media (min-width: 700px) {
    right: 28%;
  }


  @media (max-width: 600px) {
    width: 80%;
    right: 70px;
    overflow: scroll;
  }

  @media (max-width: 330px) {
    right: 30px;
  }
`;

export const ExclamationMark = styled(Exclamation)``;

export const ErrorWrapper = styled.div`
  display: flex;
  margin: .5rem 0 0 0;
  align-items: center;

  b {
    color: #E52927;
    font-size: 12px;
    margin: 0 0 0 .3rem;
    letter-spacing: 0px;
    font: italic normal normal 12px/19px Poppins;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: grid;
  padding: .5rem;
  background: #123645;
  grid-template-columns: 3fr .2fr;
  grid-template-areas: 'title' 'close';
`;

export const Title = styled.p`
  color: #FFFFFF;
  grid-area: title;
  justify-self: center;
`;


export const Close = styled.div`
  width: 15px;
  height: 15px;
  cursor: pointer;
  background: #FFFFFF;
  margin: .3rem 0 0 0;
  clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
`;

export const FormContainer = styled.div`
  width: 100%;
  padding: 2rem;
  height: fit-content;
  background-color:white ;
  .checkbox {
    margin: .5rem 0 0 0;
  }

  .button {
    display: flex;
    width: 100%;
    margin: 1rem 0 0 0;
    justify-content: center;
    align-items: center;
  }

  button {
    width: 97%;
  }
`;

export const WrapperInput = styled.div`
  display: flex;
  margin: 0 0 1rem 0;

  input:nth-child(1) {
    margin: 0 1rem 0 0;
  }

  .caracteres {
    text-align: left;
    letter-spacing: 0px;
    color: #808080;
    font-size: 12px;

    p {
      margin: .5rem 0 0 0;
    }
  }

  @media (max-width: 820px) {
    flex-direction: column;
    /* padding: 0 0 10px 0; */
    margin:0 0 2% 0 ;
  }
`;

export const Overlay = styled.div<IProps>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, .7);
  display: ${props => props.open ? 'block' : 'none'};
`;

