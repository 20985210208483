import styled from 'styled-components';

export const Container = styled.div`

  textarea {
    border: none;
    height: 2rem;
    padding: .5rem;
    border-radius: 2px;
    border: 1px solid #C2C1C1BF;
    transition: border-color 0.2s;

    &::placeholder {
      color: #000000;
    }
  }

  textarea {
    min-height: 130px;
  }
`;
