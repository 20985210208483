import styled from 'styled-components';

export const WrapperInput = styled.div`
  display: flex;
  margin: 0 0 1rem 0;

  input:nth-child(1) {
    margin: 0 1rem 0 0;
  }

  .caracteres {
    text-align: left;
    letter-spacing: 0px;
    color: #808080;
    font-size: 12px;

    p {
      margin: .5rem 0 0 0;
    }
  }

  @media (max-width: 820px) {
    flex-direction: column;
    /* padding: 0 0 1rem 0; */
    margin:0 0 2% 0 ;

  }
`;
